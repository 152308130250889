.react-tel-input .form-control {
  padding: 6px 11px !important;
  height: 40px !important;
}

.react-tel-input .flag-dropdown {
  display: none !important;
}

.form-control::placeholder {
  text-overflow: ellipsis;
  color: #b3b3b3 !important;
}

.has-error .form-control,
.has-error .form-control:hover {
  background-color: #fff;
  border-color: #f5222d;
}
