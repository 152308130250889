.col-leftside-newcustomer-address {
  background: #f5f5f5;
  border-radius: 20px 0 0 20px;
  padding: 20px 20px;
}
.province-select .anticon svg {
  color: #008cd2;
}

.form-select-shipping {
  width: 100%;
  height: 32px;
  border-width: 1px;
  border-style: solid;
  border-color: #d4d4d4;
  background: #fff;
}

.ant-select-selection--single {
  width: 100% !important;
  height: 42px !important;
}

.error-msg {
  color: red;
}

.select-has-error,
.input-has-error {
  border-color: red;
  height: 40px;
  padding: 6px 11px;
}
