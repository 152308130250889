@import url(https://use.typekit.net/wvb1lqs.css);

body {
  margin: 0;
  font-family: myriad-pro, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-confimation {
  background-color: transparent;
  color: #fff;
  height: 48px;
  border: 1px solid #fff;
  width: 98%;
}
.confimation-box {
  background: #008cd2;
  color: #fff;
  min-height: 310px;
  padding: 50px 30px;
  border-radius: 5px;
}
.btn-confimation-wrap {
  text-align: center;
  margin-bottom: 30px;
}
.confimation-heading {
  color: #fff;
}

.help-header {
  text-align: center;
}
.help-header-french {
  padding-bottom: 20px;
}
.btn-timeout-wrap {
  text-align: center;
}
.btn-timeout {
  margin: 16px 0;
}
.btn-timeout-model {
  width: 70%;
}
.call-icon-wrap {
  padding: 10px 0;
}
.call-image {
  padding-bottom: 10px;
}

.empty-catalogues-text {
    padding: 60px;
    text-align: center;
    background: #8b8c8d;
    color: white;
    font-size: 20px;
    border-radius: 5px;
}

.catelog-view {
  color: #008cd2 !important;
}
/* Landing page style */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ant-layout {
  background-color: white;
}
.header {
  padding: 0;
  background-color: white;
}
.section-1 {
  box-shadow: 0px 7px 7px rgb(0 0 0 / 2%);
}
.section-2 {
  padding: 0 50px;
  padding-top: 3%;
}
.section-2-ordernow {
  padding-bottom: 50px;
}
.section-3 {
  margin-top: 3%;
  margin-bottom: 3%;
  padding: 0 50px;
}
.section-4 {
  padding: 50px 50px;
  background: #f5f5f5;
}
.section-5 {
  padding: 50px 50px;
  margin: 0 !important;
}
.section-6 {
  padding: 50px 50px;
  background: #2b2f33;
  margin: 0 !important;
}
.account-card-left {
  background: #008cd2;
  text-align: left;
  min-height: 180px;
  color: #fff;
}
.account-card-left-text {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}
.account-card-left-subtext {
  color: #fff;
  font-weight: 100;
  padding-bottom: 24px;
}
.account-card-icon {
  padding-bottom: 10px;
}
.account-card-right {
  border: 1px solid #008cd2;
  text-align: left;
  min-height: 196px;
}
.h1-home {
  font-size: 54px;
  font-style: normal;
  font-weight: 600;
  line-height: 77px;
  letter-spacing: 0em;
  text-align: left;
  margin: 5px;
}
.p-home {
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}
.section-2-wrap {
  padding-top: 5%;
}
.section-3-box-1 {
  text-align: left;
}
.section-4-heading {
  width: 40%;
  text-align: left;
  color: #000;
  font-weight: 600;
  font-size: 24px;
}
.section-5-heading {
  width: 60%;
  text-align: left;
  padding-bottom: 2%;
  font-weight: 700;
}
.section-4-subheading {
  width: 60%;
  text-align: left;
  padding-bottom: 2%;
  padding-top: 2%;
  font-weight: 700;
}
.section-5-inner-1 {
  background: #008cd2;
  color: #fff;
  min-height: 310px;
  padding: 50px 30px;
  text-align: center;
}
.section-5-inner-1 h1 {
  color: #fff;
}
.section-5-inner-3 h1 {
  color: #fff;
  margin: 0;
}
.section-5-inner-2 {
  border: 1px solid #008cd2;
  min-height: 310px;
  padding: 50px 30px;
  text-align: center;
}
.section-5-inner-3 {
  background: #008cd2;
  color: #fff;
  min-height: 310px;
  padding: 50px 30px;
  border-radius: 5px;
  text-align: center;
}
.section-5-inner-1-heading {
  margin: 0;
}
.section-5-inner-2-heading-one {
  font-weight: 600;
  color: #000;
  margin: 0;
}
.section-5-inner-2-heading-two {
  font-weight: 600;
  color: #000;
  margin: 0;
  padding-bottom: 12px;
}
.footer-box1-subtext {
  padding-bottom: 10px;
}
.section-5-inner-3-a {
  color: #fff;
  text-decoration: underline;
}
.section-5-inner-3-a:hover {
  color: #000;
  text-decoration: underline;
}
.footer-question-item {
  padding-top: 10px;
  font-size: 16px;
}
.footer-question-item-wrap {
  display: flex;
  justify-content: center;
}
.footer-question-sub {
  padding-bottom: 10px;
}
.section-6-box-1 {
  text-align: left;
  color: #fff;
}
.section-6-box2-heading {
  color: #fff;
  text-transform: uppercase;
}
.footer-category-support {
  padding-top: 10px;
}
.section-6-box2-list {
  list-style: none;

  text-align: left;
}
.section-6-box2-list li {
  padding-bottom: 5px;
}
.section-6-box2-list li a {
  color: #a1a1a1;
  padding-bottom: 10px;
}
.subfooter {
  text-align: left;
  color: white;
}
.ant-menu-horizontal {
  border-bottom: 1px solid #fff;
  margin-left: 25px;
}
.header-sec-3 {
  padding: 14px;
}
.header-sec-2 {
  padding: 8px;
}
.sysco-header {
  padding: 0 48px;
}
.header-right {
  float: right;
}
.Language-selection {
  padding-right: 10px;
}
.btn-business-link {
  color: #56b146;
  font-weight: 600;
}
.ant-menu-submenu-title {
  font-weight: 600;
}
.nav-item {
  color: #008cd2;
}
.btn-primary-account {
  background-color: white;
  color: #000;
  height: 48px;
  font-weight: 600;
}
.btn-primary-business-account {
  background: #56b146;
  border: none;
  height: 48px;
}
.ant-btn:active {
  color: #fff !important;
}
.ant-btn:focus {
  color: #fff !important;
}
.btn-business-link:active {
  color: #56b146 !important;
}
.btn-business-link:focus {
  color: #56b146 !important;
}
.catelog-card {
  margin: 0 20px;
}
.catelog-card .ant-card-body {
  padding: 0 0 30px 0;
  text-align: left;
}
.catelog-view {
  text-decoration: underline;
  font-weight: 600;
  padding-left: 20px;
}
.epbijV {
  background-color: rgb(0 140 210 / 56%) !important;
  box-shadow: 0 0 1px 3px rgb(0 140 210) !important;
}
.VHmVZ {
  width: 100%;
  justify-content: flex-end;
  text-align: right;
}
.btPwzg {
  background-color: rgb(239 250 255) !important;
}
.btPwzg:disabled {
  position: absolute;
  bottom: -36px;
  left: 30px;
}
.jsaYsO {
  position: absolute;
  bottom: -34px;
  left: 90px;
}
.jsaYsO:hover:enabled,
.jsaYsO:focus:enabled {
  background-color: rgb(0 140 210) !important;
}
.jsaYsO {
  background-color: rgb(0 140 210) !important;
  color: #fff !important;
}
.catelog-img {
  width: 160px;
  height: 213px;
}
.catelogu-title {
  padding-top: 16px;
  padding-left: 20px;
  word-wrap: break-word;
  width: 150px;
}
.divider {
  border: 1px solid #e8e8e8;
}

.home-customer {
  padding-bottom: 21px;
  width: 230px;
  font-weight: 600;
  font-size: 18px;
  color: #000;
}
.homepage-section-3-item-image1 {
  padding-bottom: 10px;
}
.styling-example {
  min-height: 80px;
}
.catelog-view .anticon svg {
  color: #008cd2 !important;
}
.menu-link {
  font-weight: 600;
  color: #008cd2 !important;
}
.menu-divider {
  border: 1px #f7f7f7 solid;
}
.social-media {
  width: 40px;
  padding-bottom: 10px;
}
.social-media-list {
  list-style: none;
}
.search-wrap .anticon svg {
  color: #868686;
  margin-right: 10px;
  font-size: 20px;
}
input:focus:invalid,
select:focus:invalid,
textarea:focus:invalid {
  border-color: red;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #56b146;
  background: #56b146;
}

.react-tel-input .form-control.invalid-number {
  border: 1px solid #cacaca !important;
  background-color: #ffffff !important;
  border-left-color: #ffffff !important;
}
.react-tel-input .form-control.invalid-number:focus {
  border: 1px solid red !important;
  background-color: #ffffff !important;
  border-left-color: #ffffff !important;
}
.homepage-infobox-text {
  font-weight: 600;
  color: #000;
}
.homepage-infobox-subtext {
  font-size: 18px;
  color: #5d5d5d;
  width: 80%;
}

/* Loader */
.loader-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #0000002e;
  top: 0;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
.loader {
  width: 100px;
  height: 100px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='margin: auto;display: block;' width='100px' height='100px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'%3E%3Ccircle cx='50' cy='50' r='40' stroke='%2356b146' stroke-width='5' fill='none'%3E%3C/circle%3E%3Cpath d='M15 50 Q32.5 40 50 50 Q67.5 60 85 50 A35 35 0 0 1 15 50' fill='%23008cd2'%3E%3Canimate attributeName='d' repeatCount='indefinite' dur='1s' calcMode='spline' keyTimes='0;0.5;1' values='M15 50 Q32.5 40 50 50 Q67.5 60 85 50 A35 35 0 0 1 15 50;M15 50 Q32.5 60 50 50 Q67.5 40 85 50 A35 35 0 0 1 15 50;M15 50 Q32.5 40 50 50 Q67.5 60 85 50 A35 35 0 0 1 15 50' keySplines='0.5 0 0.5 1;0.5 0 0.5 1'%3E%3C/animate%3E%3C/path%3E%3C/svg%3E");
}

@media only screen and (min-width: 360px) and (max-width: 374px) {
  .section-6-box-1 {
    text-align: center;
  }
  .h1-home {
    text-align: center;
  }
  .p-home {
    text-align: center;
  }
  .account-card-left {
    text-align: center;
  }
  .account-card-right {
    text-align: center;
    margin-top: 10px;
  }
  .section-3-box-1 {
    text-align: center;
  }
  .homepage-infobox-subtext {
    width: 100%;
  }
  .section-4-heading {
    width: 100%;
    text-align: center;
  }
  .section-5-heading {
    width: 100%;
    text-align: center;
    font-size: 24px;
  }
  .section-5-inner-1 {
    padding: 50px 10px;
  }
  .section-5-inner-2 {
    padding: 50px 10px;
  }
  .section-5-inner-3 {
    padding: 50px 10px;
  }
  .section-5 {
    padding: 50px 0 0 0;
  }
  .section-2 {
    padding: 0 10px;
  }
  .section-2-ordernow {
    padding-bottom: 10px;
  }
  .home-customer {
    width: 100%;
  }
  .section-4-subheading {
    width: 100%;
    text-align: center;
  }
  .section-6-box2-list {
    padding-left: 0;
    text-align: center;
  }
  .social-media-list {
    text-align: center;
  }
  .section-5-inner-2 {
    border: none;
  }
  .wrap-section-5-inner-1 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .wrap-section-5-inner-2 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .wrap-section-5-inner-3 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .ant-modal-close-x {
    line-height: 42px;
    width: 40px;
    height: 40px;
    margin: 20px 10px !important;
  }
  .Language-selection-newcustomer-withoutstep {
    padding: 30px 10px !important;
  }
  .confimation-box {
    padding: 50px 10px;
  }
  .home-header-logo {
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    display: flex;
  }
  .header-sec-3-mobile {
    text-align: center;
  }
  .header-sec-2 {
    padding: 4px;
  }
  .social-footer-mobile {
    display: flex;
    justify-content: space-evenly;
  }
}
@media only screen and (min-width: 375px) and (max-width: 413px) {
  .h1-home {
    text-align: center;
  }
  .p-home {
    text-align: center;
  }
  .account-card-left {
    text-align: center;
  }
  .account-card-right {
    text-align: center;
    margin-top: 10px;
  }
  .section-3-box-1 {
    text-align: center;
  }
  .homepage-infobox-subtext {
    width: 100%;
  }
  .section-4-heading {
    width: 100%;
    text-align: center;
  }
  .section-5-heading {
    width: 100%;
    text-align: center;
    font-size: 24px;
  }
  .section-5-inner-1 {
    padding: 50px 10px;
  }
  .section-5-inner-2 {
    padding: 50px 10px;
  }
  .section-5-inner-3 {
    padding: 50px 10px;
  }
  .section-5 {
    padding: 50px 0 0 0;
  }
  .section-2 {
    padding: 0 10px;
  }
  .section-2-ordernow {
    padding-bottom: 10px;
  }
  .home-customer {
    width: 100%;
  }
  .section-4-subheading {
    width: 100%;
    text-align: center;
  }
  .section-6-box2-list {
    padding-left: 0;
    text-align: center;
  }
  .section-6-box-1 {
    text-align: center;
  }
  .social-media-list {
    text-align: center;
  }
  .section-5-inner-2 {
    border: none;
  }
  .wrap-section-5-inner-1 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .wrap-section-5-inner-2 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .wrap-section-5-inner-3 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .ant-modal-close-x {
    line-height: 42px;
    width: 40px;
    height: 40px;
    margin: 20px 10px !important;
  }
  .Language-selection-newcustomer-withoutstep {
    padding: 30px 10px !important;
  }
  .confimation-box {
    padding: 50px 10px;
  }
  .home-header-logo {
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    display: flex;
  }
  .header-sec-3-mobile {
    text-align: center;
  }
  .header-sec-2 {
    padding: 4px;
  }
  .social-footer-mobile {
    display: flex;
    justify-content: space-evenly;
  }
}
@media only screen and (min-width: 414px) and (max-width: 767px) {
  .h1-home {
    text-align: center;
  }
  .p-home {
    text-align: center;
  }
  .account-card-left {
    text-align: center;
  }
  .account-card-right {
    text-align: center;
    margin-top: 10px;
  }
  .section-3-box-1 {
    text-align: center;
  }
  .homepage-infobox-subtext {
    width: 100%;
  }
  .section-4-heading {
    width: 100%;
    text-align: center;
  }
  .section-5-heading {
    width: 100%;
    text-align: center;
    font-size: 24px;
  }
  .section-5-inner-1 {
    padding: 50px 10px;
  }
  .section-5-inner-2 {
    padding: 50px 10px;
  }
  .section-5-inner-3 {
    padding: 50px 10px;
  }
  .section-5 {
    padding: 50px 0 0 0;
  }
  .section-2 {
    padding: 0 10px;
  }
  .section-2-ordernow {
    padding-bottom: 10px;
  }
  .home-customer {
    width: 100%;
  }
  .section-4-subheading {
    width: 100%;
    text-align: center;
  }
  .section-6-box2-list {
    padding-left: 0;
    text-align: center;
  }
  .social-media-list {
    text-align: center;
  }
  .section-5-inner-2 {
    border: none;
  }
  .wrap-section-5-inner-1 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .wrap-section-5-inner-2 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .wrap-section-5-inner-3 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .section-6-box-1 {
    text-align: center;
  }
  .ant-modal-close-x {
    line-height: 42px;
    width: 40px;
    height: 40px;
    margin: 20px 10px !important;
  }
  .Language-selection-newcustomer-withoutstep {
    padding: 30px 10px !important;
  }
  .confimation-box {
    padding: 50px 10px;
  }
  .home-header-logo {
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    display: flex;
  }
  .header-sec-3-mobile {
    text-align: center;
  }
  .header-sec-2 {
    padding: 4px;
  }
  .social-footer-mobile {
    display: flex;
    justify-content: space-evenly;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .section-5-inner-wrap {
    zoom: 1.2;
  }
  .section-5-inner-1 {
    margin-bottom: 10px;
  }
  .section-5-inner-2 {
    margin-bottom: 10px;
  }
  .section-4-heading {
    width: 100%;
  }
  .ant-modal-close-x {
    line-height: 42px;
    width: 40px;
    height: 40px;
    margin: 20px 10px !important;
  }
  .Language-selection-newcustomer-withoutstep {
    padding: 30px 10px !important;
  }
  .confimation-box {
    padding: 50px 10px;
  }
  .contactus-model {
    width: 700px !important;
  }
  .home-header-logo {
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    display: flex;
  }
  .header-sec-3-mobile {
    text-align: center;
  }
  .header-sec-2 {
    padding: 4px;
  }
}
@media only screen and (min-width: 801px) and (max-width: 1024px) {
  .contactus-model {
    width: 990px !important;
  }
  .section-5-inner-3 {
    padding: 72px 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .contactus-model {
    width: 700px !important;
  }
  .section-5-inner-ipad {
    zoom: 1.2;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1536px) {
  .catelogu-title {
    min-height: 90px;
  }
}
@media only screen and (min-width: 1537px) and (max-width: 1920px) {
  .catelogu-title {
    min-height: 60px;
  }
}

.ant-modal-body {
  padding: 0 !important;
}
.col-leftside-contact {
  background: #ffffff;
  border-radius: 20px;
  padding: 70px 40px 10px 40px;
}
.ant-modal-content {
  border-radius: 20px !important;
}
.leftside-image {
  width: 370px;
  padding: 40px;
}
.col-rightside {
  padding: 160px 40px 40px 40px;
}
.ant-modal-close-x {
  background: #008cd2;
  border-radius: 50px;
  margin: 10px;
}
.anticon svg {
  color: white;
}
.catelog-lable {
  padding-bottom: 10px;
  display: block;
  padding-top: 10px;
}
.catelog-btn {
  padding: 15px 40px !important;
  height: auto !important;
  background: #008cd2 !important;
  margin-top: 10px;
  color: #fff;
}
.download-text-contact {
  margin: 0;
  font-weight: 700;
  font-size: 26px;
}
.Thank-you {
  position: absolute;
  left: 40px;
  top: 220px;
  font-weight: 600;
}
.thankyou-wraper {
  padding: 40px;
}
.contactus-form .col-leftside-contact input {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.col-rightside-form input {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
  width: 95%;
}
.contact-phone-field input {
  width: 95% !important;
}
.contact-submit-btn {
  padding: 20px 40px;
}

@media only screen and (min-width: 360px) and (max-width: 374px) {
  .col-leftside-contact {
    padding: 70px 40px 0px 40px;
  }
  .col-rightside {
    padding: 0px 40px 10px 40px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 413px) {
  .col-leftside-contact {
    padding: 70px 40px 0px 40px;
  }
  .col-rightside {
    padding: 0px 40px 10px 40px;
  }
}
@media only screen and (min-width: 414px) and (max-width: 767px) {
  .col-leftside-contact {
    padding: 70px 40px 0px 40px;
  }
  .col-rightside {
    padding: 0px 40px 10px 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .col-leftside-contact {
    padding: 70px 40px 0px 40px;
  }
  .col-rightside {
    padding: 0px 40px 10px 40px;
  }
}

.react-tel-input .form-control {
  padding: 6px 11px !important;
  height: 40px !important;
}

.react-tel-input .flag-dropdown {
  display: none !important;
}

.form-control::-webkit-input-placeholder {
  text-overflow: ellipsis;
  color: #b3b3b3 !important;
}

.form-control:-ms-input-placeholder {
  text-overflow: ellipsis;
  color: #b3b3b3 !important;
}

.form-control::placeholder {
  text-overflow: ellipsis;
  color: #b3b3b3 !important;
}

.has-error .form-control,
.has-error .form-control:hover {
  background-color: #fff;
  border-color: #f5222d;
}

.ant-modal-body {
  padding: 0 !important;
}
.col-leftside {
  background: #008cd2;
  border-radius: 5px;
}
.col-leftside {
  background: #008cd2;
  border-radius: 5px;
}
.col-leftside-catalogue {
  background: #008cd2;
  border-radius: 20px 0 0 20px;
  padding: 50px 0;
}
.ant-modal-content {
  border-radius: 20px !important;
}
.leftside-image {
  width: 370px;
  padding: 40px;
}
.col-rightside-catalogues {
  padding: 29px !important;
}
.catelogue-field-phone input {
  width: 95% !important;
}
.catelogue-field-wrap input {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-modal-close-x {
  background: #008cd2;
  border-radius: 50px;
  margin: 10px;
}
.anticon svg {
  color: white;
}
.catelog-lable {
  padding-bottom: 10px;
  display: block;
  padding-top: 10px;
}
.catelog-btn {
  padding: 15px 40px !important;
  height: auto !important;
  background: #008cd2 !important;
  margin-top: 10px;
}
.dowload-text {
  margin: 0;
  font-weight: 700;
  font-size: 20px;
}
.Thank-you {
  position: absolute;
  left: 40px;
  top: 220px;
  font-weight: 600;
}
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .col-leftside-catalogue {
    background: transparent;
    border-radius: 20px;
    padding: 30px 0px 0px 0px;
    text-align: center;
  }
  .leftside-image {
    width: 340px;
    padding: 36px 40px 0px 40px;
  }
  .Thank-you {
    position: relative;
    left: 0;
    top: 0;
    font-weight: 600;
    text-align: center;
    padding-top: 20px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 413px) {
  .col-leftside-catalogue {
    background: transparent;
    border-radius: 20px;
    padding: 30px 0px 0px 0px;
    text-align: center;
  }
  .leftside-image {
    width: 340px;
    padding: 36px 40px 0px 40px;
  }
  .Thank-you {
    position: relative;
    left: 0;
    top: 0;
    font-weight: 600;
    text-align: center;
    padding-top: 20px;
  }
}
@media only screen and (min-width: 414px) and (max-width: 767px) {
  .col-leftside-catalogue {
    background: transparent;
    border-radius: 20px;
    padding: 30px 0px 0px 0px;
    text-align: center;
  }
  .leftside-image {
    width: 340px;
    padding: 36px 40px 0px 40px;
  }
  .Thank-you {
    position: relative;
    left: 0;
    top: 0;
    font-weight: 600;
    text-align: center;
    padding-top: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .col-leftside-catalogue {
    background: transparent;
    border-radius: 20px;
    padding: 30px 0px 0px 0px;
    text-align: center;
  }
  .leftside-image {
    width: 340px;
    padding: 36px 40px 0px 40px;
  }
  .Thank-you {
    position: relative;
    left: 0;
    top: 0;
    font-weight: 600;
    text-align: center;
    padding-top: 20px;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1536px) {
  .catelog-card .ant-card-body {
    min-height: 280px;
  }
}
@media only screen and (min-width: 1537px) and (max-width: 1920px) {
  .catelog-card .ant-card-body {
    min-height: 250px;
  }
}

.div-error {
  padding: 40px;
}

.h1-error-label {
  padding-top: 60px;
}

.col-leftside-aboutyou {
  background: #f5f5f5;
  border-radius: 20px 0 0 20px;
  padding: 70px 20px;
}
.form-btn-link {
  padding: 15px 40px !important;
  height: auto !important;
  background: transparent !important;
  margin-top: 10px;
  color: #008cd2;
  border: none !important;
  box-shadow: none;
}
.col-leftside-aboutyou-model {
  background: #f5f5f5;
  border-radius: 20px 0 0 20px;
  padding: 20px 20px;
}
.ant-steps-item-content {
  display: block;
}
.ncob-steps {
  padding: 16px 0;
  display: block;
}
.ant-steps-small .ant-steps-item-title {
  line-height: 25px;
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: #1890ff !important;
  background: transparent;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #008cd2 !important;
}
.input-first {
  width: 95%;
}

.SyscoAssociate {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  margin-right: 10px;
}
.SyscoAssociate:hover {
  background: #e8e8e8;
  border: 1px solid #008cd2;
}
.SyscoAssociate:hover {
  border: 1px solid #4dc3ff;
}
.SyscoAssociate-selected {
  border: 2px solid #008cd2;
}
.SyscoAssociate .order-now-delivery svg {
  color: #525050 !important;
  margin-right: 10px;
  border-radius: 50px;
  padding: 5px;
  border: 1px solid;
  width: 20px;
  height: 20px;
  position: relative;
  top: 2px;
}
.SyscoAssociate-selected .order-now-delivery svg {
  color: #ffffff !important;
  background: #56b146;
  margin-right: 10px;
  border-radius: 50px;
  padding: 2px;
  width: 20px;
  height: 20px;
}
.ant-form-item {
  margin-bottom: 10px;
}
.ant-form-item-control {
  margin-right: 10px;
}
.about-phone-number input {
  width: 100% !important;
}
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .Language-selection-newcustomer {
    padding: 30px 80px;
  }
  .model-header-rep .ant-steps-horizontal.ant-steps-label-horizontal {
    padding-left: 20px;
    min-height: 100px;
  }
  .language-selection-rep {
    padding: 4px 10px 45px 10px;
    min-height: 132px;
    display: block;
  }
}
@media only screen and (min-width: 375px) and (max-width: 413px) {
  .Language-selection-newcustomer {
    padding: 30px 80px;
  }
  .model-header-rep .ant-steps-horizontal.ant-steps-label-horizontal {
    padding-left: 20px;
    min-height: 100px;
  }
  .language-selection-rep {
    padding: 4px 10px 45px 10px;
    min-height: 132px;
    display: block;
  }
}
@media only screen and (min-width: 414px) and (max-width: 767px) {
  .Language-selection-newcustomer {
    padding: 30px 80px;
  }
  .model-header-rep .ant-steps-horizontal.ant-steps-label-horizontal {
    padding-left: 20px;
    min-height: 100px;
  }
  .language-selection-rep {
    padding: 4px 10px 45px 10px;
    min-height: 132px;
    display: block;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .Language-selection-newcustomer {
    padding: 30px 80px;
  }
  .newcustomer-heading-become {
    font-size: 15px !important;
    line-height: 14px !important;
    padding-top: 23px !important;
  }
  .language-selection-rep {
    padding: 30px 15px;
    display: block;
  }
}
@media only screen and (min-width: 801px) and (max-width: 1024px) {
  .Language-selection-newcustomer {
    padding: 30px 40px !important;
  }
  .newcustomer-heading-become {
    font-size: 14px !important;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1365px) {
  .language-selection-rep {
    padding: 30px 10px 30px 10px;
    min-height: 40px;
    display: block;
  }
  .modelheader-syscorep .newcustomer-heading-become {
    font-size: 15px !important;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1439px) {
  .language-selection-rep {
    padding: 30px 10px 30px 10px;
    min-height: 40px;
    display: block;
  }
  .modelheader-syscorep .newcustomer-heading-become {
    font-size: 15px !important;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1536px) {
  .language-selection-rep {
    padding: 30px 10px 30px 10px;
    min-height: 40px;
    display: block;
  }
  .newcustomer-heading-become-desktop {
    font-size: 18px !important;
  }
  .modelheader-syscorep .newcustomer-heading-become {
    font-size: 15px !important;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1537px) and (max-width: 1920px) {
  .language-selection-rep {
    padding: 30px 10px 30px 10px;
    min-height: 40px;
    display: block;
  }
  .newcustomer-heading-become-desktop {
    font-size: 18px !important;
  }
  .modelheader-syscorep .newcustomer-heading-become {
    font-size: 15px !important;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1921px) {
  .language-selection-rep {
    padding: 30px 10px 30px 10px;
    min-height: 40px;
    display: block;
  }
}

.h1-newcustomer {
  color: #929292;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 77px;
  text-align: left;
  margin: 5px;
}
.newcustomer-heading {
  color: #000000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 77px;
  text-align: left;
  margin: 2px;
  padding-left: 30px;
}
.newcustomer-heading-become {
  color: #000000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  margin: 2px;
  padding-left: 30px;
  padding-right: 10px;
  padding-bottom: 26px;
  padding-top: 37px;
}
.sysco-rep {
  padding: 0 70px;
}
.credit-term {
  padding: 0;
}
.model-header {
  box-shadow: 0 8px 6px -6px #e6e6e659;
  background: white;
}
.model-body {
  background: #f5f5f5;
}
.p-newcustomer {
  font-size: 16px;
  font-weight: 600;
}
.Language-selection-newcustomer {
  padding: 30px 80px;
  display: block;
}
.Language-selection-newcustomer-withoutstep {
  padding: 30px 30px;
  display: block;
}
.col-rightside-newcustomer {
  padding: 20px !important;
}
.col-rightside-newcustomer-box {
  background-color: #fff;
  padding: 20px;
}
.newcustomer-rightside-text {
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
}
.col-left-newcustomer {
  padding: 20px 20px 30px 30px;
}
.Newcustomer-option1 {
  padding: 10px;
  background: white;
  margin-bottom: 10px;
  border-radius: 5px;
}
.Newcustomer-option1:hover {
  background: #f3f3f3;
  cursor: pointer;
  border: #cccccc 1px solid;
}
.Newcustomer-option-businesspartner {
  padding-top: 10px;
}
.Newcustomer-option1-selected {
  border: 2px solid #008cd2;
}
.Newcustomer-option1-selected .order-now-delivery {
  background: #008cd2;
  border: #008cd2 1px solid;
  color: #fff;
}
.Newcustomer-option1-selected .order-now-delivery-selected svg {
  color: #ffffff !important;
  background: #56b146;
  margin-right: 10px;
  border-radius: 50px;
  padding: 5px;
  width: 20px;
  height: 20px;
  position: relative;
  top: 2px;
}
.Newcustomer-option1 .order-now-delivery svg {
  color: #525050 !important;
  margin-right: 10px;
  border-radius: 50px;
  padding: 5px;
  border: 1px solid;
  width: 20px;
  height: 20px;
  position: relative;
  top: 2px;
}
.h1-letsgetstarted-wrap {
  padding: 0px 20px 0px 30px;
}
.h1-letsgetstarted {
  color: #929292;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  padding-top: 20px;
  text-align: left;
  margin: 0px;
}
.option1-title {
  font-weight: 600;
  color: black;
  margin-bottom: 0;
  display: inline-block;
}
.option2-title {
  font-weight: 600;
  color: black;
  margin-bottom: 0;
  display: inline-block;
}
.option3-title {
  font-weight: 600;
  color: black;
  margin-bottom: 0;
}
.business-partner-text {
  padding-bottom: 15px;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}
.qesstion-call {
  font-weight: 700;
  display: inline-block;
  margin: 0;
  padding-top: 10px;
  padding-right: 5px;
}
.newcustomer-btn-continue {
  margin-top: 8vh;
}

.p-industry {
  padding: 30px 30px 0px 30px;
}
.industry-item {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
}
.industry-item:hover {
  background: #e8e8e8;
  border: 1px solid #008cd2;
  cursor: pointer;
}
.col-leftside-yourbusiness {
  padding: 0px 30px 0px 30px;
}
.segment-text {
  padding: 10px 30px 0px 30px;
}
.segment-text .anticon svg {
  color: #008cd2;
}
.segment-form .ant-form-item-control {
  margin: 0;
}
.form-select {
  width: 100%;
}
.yourbusiness-footer {
  padding-bottom: 20px;
}
.industry-icon-wrap {
  border: #bfbfbf 1px solid;
  padding: 2px 5px 4px 6px;
  border-radius: 50px;
  margin-right: 10px;
}
.industry-selected {
  border: #008cd2 2px solid;
}

.industry-item:hover {
  border: #008cd2 1px solid;
}
.industry-selected span {
  background: #008cd2;
  border: #008cd2 1px solid;
}
.segment-lable {
  margin: 0;
}
.ant-select-selection--single .ant-select-selection__rendered {
  margin-top: 6px;
}

.ant-steps-item-process-business {
  border-color: #bfbfbf !important;
  background: transparent;
  color: #bfbfbf !important;
}

.ant-steps-item-process-business .ant-steps-item-icon {
  border-color: #bfbfbf !important;
}

.ant-steps-item-process-business > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}

.ant-steps-item-process-business .ant-steps-item-icon > .ant-steps-icon {
  color: #bfbfbf !important;
  border-color: #bfbfbf !important;
}

.newcustomer-heading-become {
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  margin: 2px;
  padding-left: 30px;
  padding-right: 10px;
  padding-bottom: 26px;
  padding-top: 23px;
  width: 90%;
}

.sysco-rep-business {
  padding: 0 50px;
}

.credit-term {
  padding: 0;
}

@media only screen and (min-width: 360px) and (max-width: 374px) {
  .Language-selection-newcustomer-billing {
    min-height: 320px;
  }
  .Language-selection-newcustomer-without-billing {
    min-height: 272px;
  }
  .Language-selection-newcustomer {
    padding: 5px 10px 245px 10px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal {
    padding-left: 20px;
    min-height: 240px;
  }
  .newcustomer-heading-become {
    width: 90%;
  }
  .sysco-rep-steps .ant-steps-horizontal.ant-steps-label-horizontal {
    padding-left: 20px;
    min-height: 100px;
  }
  .syscorep-language {
    padding: 5px 10px 105px 10px;
    min-height: 130px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 413px) {
  .Language-selection-newcustomer-billing {
    min-height: 320px;
  }
  .Language-selection-newcustomer-without-billing {
    min-height: 272px;
  }
  .Language-selection-newcustomer {
    padding: 5px 10px 245px 10px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal {
    padding-left: 20px;
    min-height: 240px;
  }
  .newcustomer-heading-become {
    width: 90%;
  }
  .sysco-rep-steps .ant-steps-horizontal.ant-steps-label-horizontal {
    padding-left: 20px;
    min-height: 100px;
  }
  .syscorep-language {
    padding: 5px 10px 105px 10px;
    min-height: 130px;
  }
}
@media only screen and (min-width: 414px) and (max-width: 767px) {
  .Language-selection-newcustomer-billing {
    min-height: 320px;
  }
  .Language-selection-newcustomer-without-billing {
    min-height: 272px;
  }
  .Language-selection-newcustomer {
    padding: 5px 10px 245px 10px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal {
    padding-left: 20px;
    min-height: 240px;
  }
  .newcustomer-heading-become {
    width: 90%;
  }
  .sysco-rep-steps .ant-steps-horizontal.ant-steps-label-horizontal {
    padding-left: 20px;
    min-height: 100px;
  }
  .syscorep-language {
    padding: 5px 10px 105px 10px;
    min-height: 130px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .Language-selection-newcustomer {
    padding: 53px 10px;
  }
  .newcustomer-heading-become {
    width: 90%;
    padding-top: 32px !important;
    padding-bottom: 32px;
    max-height: 77px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .Language-selection-newcustomer {
    padding: 30px 10px;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1365px) {
  .Language-selection-newcustomer {
    padding: 30px 60px;
  }
  .newcustomer-heading-become-desktop-wrap p {
    font-size: 16px !important;
    line-height: 14px;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1439px) {
  .Language-selection-newcustomer {
    padding: 30px 60px;
  }
  .newcustomer-heading-become-desktop-wrap p {
    font-size: 16px !important;
    line-height: 14px;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1536px) {
  .Language-selection-newcustomer {
    padding: 30px 60px;
  }
  .newcustomer-heading-become-desktop-wrap .newcustomer-heading-business-desktop {
    font-size: 14px !important;
  }
  .newcustomer-heading-become-desktop-card .newcustomer-heading-business-desktop {
    font-size: 18px !important;
  }
  .newcustomer-heading-become-desktop-wrap p {
    font-size: 16px !important;
    line-height: 14px;
  }
}
@media only screen and (min-width: 1537px) and (max-width: 1920px) {
  .Language-selection-newcustomer {
    padding: 30px 60px;
  }
  .newcustomer-heading-become-desktop-card .newcustomer-heading-business-desktop {
    font-size: 18px !important;
  }
  .newcustomer-heading-become-desktop-wrap p {
    font-size: 16px !important;
    line-height: 14px;
  }
}

.shippingdetails-form-wrap input {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.biiling-shipping-same {
  padding-top: 16px;
}
.form-select-shipping-address {
  width: 100%;
  height: 32px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background: #fff;
}
.billing-verify-checkbox {
  padding-top: 10px;
}

.col-leftside-newcustomer-address {
  background: #f5f5f5;
  border-radius: 20px 0 0 20px;
  padding: 20px 20px;
}
.province-select .anticon svg {
  color: #008cd2;
}

.form-select-shipping {
  width: 100%;
  height: 32px;
  border-width: 1px;
  border-style: solid;
  border-color: #d4d4d4;
  background: #fff;
}

.ant-select-selection--single {
  width: 100% !important;
  height: 42px !important;
}

.error-msg {
  color: red;
}

.select-has-error,
.input-has-error {
  border-color: red;
  height: 40px;
  padding: 6px 11px;
}

.Language-selection-newcustomer-withoutstep {
  padding: 30px 30px;
  display: block;
}
.col-centered-newcustomer-address {
  background: #f5f5f5;
  border-radius: 20px 0 0 20px;
  padding: 20px 20px;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

@media only screen and (min-width: 360px) and (max-width: 374px) {
  .newcustomer-heading {
    font-size: 12px;
  }
  .newcustomer-heading-terms {
    font-size: 11px !important;
  }
  .tandc-pdf {
    width: 310px;
  }
  .tandc-pdf .react-pdf__Page__svg,
  .tandc-pdf svg {
    width: 310px !important;
  }
}
@media only screen and (min-width: 375px) and (max-width: 413px) {
  .newcustomer-heading {
    font-size: 12px;
  }
  .newcustomer-heading-terms {
    font-size: 11px !important;
  }
  .tandc-pdf {
    width: 310px;
  }
  .tandc-pdf .react-pdf__Page__svg,
  .tandc-pdf svg {
    width: 310px !important;
  }
  .tandc-pdf .react-pdf__Page__svg {
    width: 310px !important;
  }
}
@media only screen and (min-width: 414px) and (max-width: 767px) {
  .newcustomer-heading {
    font-size: 12px;
  }
  .tandc-pdf {
    width: 360px;
  }
  .tandc-pdf .react-pdf__Page__svg,
  .tandc-pdf svg {
    width: 360px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 600px) {
  .newcustomer-heading {
    font-size: 12px;
  }
  .tandc-pdf {
    width: 660px;
  }
  .tandc-pdf .react-pdf__Page__svg,
  .tandc-pdf svg {
    width: 600px !important;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1365px) {
  .tandc-pdf {
    width: 1140px;
  }
  .tandc-pdf .react-pdf__Page__svg,
  .tandc-pdf svg {
    width: 600px !important;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1439px) {
  .tandc-pdf {
    width: 1150px;
  }
  .tandc-pdf .react-pdf__Page__svg,
  .tandc-pdf svg {
    width: 600px !important;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1536px) {
  .tandc-pdf {
    width: 1150px;
  }
  .tandc-pdf .react-pdf__Page__svg,
  .tandc-pdf svg {
    width: 600px !important;
  }
}
@media only screen and (min-width: 1537px) and (max-width: 1920px) {
  .tandc-pdf {
    width: 1150px;
  }
  .tandc-pdf .react-pdf__Page__svg,
  .tandc-pdf svg {
    width: 600px !important;
  }
}

.rewards-item {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  margin-right: 20px;
  border: 1px solid #eaeaea;
  height: 180px;
  margin-bottom: 10px;
}

.menu-divider-rewards {
  border: 1px #eaeaea solid;
}
.industry-text .ant-form-item {
  margin: 0;
}
.hear-aboutus {
  padding-bottom: 10px;
}
.hear-aboutus .anticon svg {
  color: #008cd2;
}
.rewards-and-receive {
  display: inline-block;
  font-size: 16px;
  font-weight: 800;
  padding-left: 10px;
}
.btn-back .anticon svg {
  color: #008cd2;
}
.linkSeparator {
  display: inline-block;
  margin-bottom: -0.7em;
  height: 2em;
  width: 0.14em;
  background-color: #008cd2;
  font-weight: 100;
  margin-right: 1.2em;
}
.btn-back:focus {
  color: #000 !important;
}
.btn-back:hover {
  color: #000;
}
.btn-back:hover .anticon svg {
  color: #000;
}
.catelog-btn:hover {
  color: #fff;
  background-color: #006a9e !important;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #56b146;
  background: #56b146;
}
.ant-steps-item-finish .ant-steps-item-icon .anticon svg {
  color: #ffffff;
}
@media only screen and (min-width: 768px) and (max-width: 833px) {
  .rewards-item {
    margin-right: 13px;
  }
}
@media only screen and (min-width: 834px) and (max-width: 1024px) {
  .rewards-item {
    margin-right: 1px;
  }
}

.complete-btn {
  padding: 15px 40px !important;
  height: auto !important;
  background: white !important;
  margin-top: 10px;
  border: none;
  box-shadow: none;
  color: #000;
}
.complete-btn:hover {
  color: #000;
  background-color: #ededed !important;
  border-color: #40a9ff;
}
.complete-btn:active {
  color: #000 !important;
}
.complete-btn:focus {
  color: #000 !important;
}

.col-leftside-becomeacustomer-complte {
  background: #008cd2;
  padding: 20px 20px;
}
.register {
  color: #fff;
}
.RightOutlined-complete svg {
  color: #7b7b7b;
}

.col-leftside-pickup {
  background: #f5f5f5;
  border-radius: 20px 0 0 20px;
  padding: 10px 0px 0px 20px;
}
.estimate-dropdown .anticon svg {
  color: #008cd2;
}
.estimate-dropdown-lable {
  padding-bottom: 10px;
  display: block;
  padding-top: 0;
}
.delivery-footer {
  padding-bottom: 20px;
}
.receive-order-delivery {
  width: 95%;
  cursor: pointer;
}
.ant-select-selection--single {
  height: 42px;
  width: 200px;
}
.industry-item-delivery {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  width: 95%;
  cursor: pointer;
}
.your-order-slot {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
.your-order-slot:hover {
  background: #e8e8e8;
}
.day-selected {
  border: #008cd2 2px solid;
}
.industry-item-delivery:hover {
  background: #e8e8e8;
}
.pickup-option1-selected {
  border: 2px solid #008cd2;
}
.pickup-option1-selected .order-now-delivery svg {
  color: #ffffff !important;
  background: #56b146;
  margin-right: 10px;
  border-radius: 50px;
  padding: 2px;
  width: 20px;
  height: 20px;
}
.order-now-delivery svg {
  color: #525050 !important;
  margin-right: 10px;
  border-radius: 50px;
  padding: 5px;
  border: 1px solid;
  width: 20px;
  height: 20px;
  position: relative;
  top: 2px;
}

.order-model-body {
  min-height: 60vh;
}
.catelog-btn-disabled {
  padding: 15px 40px !important;
  height: auto !important;
  background: #8f8f8f !important;
  margin-top: 10px;
}
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .col-leftside-pickup {
    padding: 10px 15px 10px 20px;
  }
  .km-number {
    font-size: 18px !important;
    padding-top: 5px;
    margin: 4px;
    font-weight: 600;
  }
  .delivery-sysco-cat {
    padding-top: 0px !important;
  }
}
@media only screen and (min-width: 375px) and (max-width: 413px) {
  .col-leftside-pickup {
    padding: 10px 15px 10px 20px;
  }
  .km-number {
    font-size: 18px !important;
    padding-top: 5px;
    margin: 4px;
    font-weight: 600;
  }
  .delivery-sysco-cat {
    padding-top: 0px !important;
  }
}
@media only screen and (min-width: 414px) and (max-width: 767px) {
  .col-leftside-pickup {
    padding: 10px 15px 10px 20px;
  }
  .km-number {
    font-size: 18px !important;
    padding-top: 5px;
    margin: 4px;
    font-weight: 600;
  }
  .delivery-sysco-cat {
    padding-top: 0px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
}

.km-box {
  background: #008cd2;
  text-align: center;
  color: white;
  border-radius: 5px;
  margin-right: 16px;
}
.delivery-sysco-address {
  margin: 0;
}
.industry-item-pickup {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
}
.industry-item-pickup:hover {
  background: #e8e8e8;
  cursor: pointer;
}
.km-number {
  font-size: 30px;
  padding-top: 5px;
  margin: -5px;
  font-weight: 700;
}
.km-text {
  font-size: 18px;
  font-weight: 400;
  padding-top: 0;
  padding-bottom: 10px;
  margin: -7px -5px;
}
.delivery-sysco-cat {
  margin: 0;
  font-weight: 700;
  padding-top: 10px;
}
.pickup-footer {
  padding-bottom: 20px;
}
.delivery-option1-selected {
  border: 1px solid #008cd2;
}
.delivery-option1-selected .order-now-delivery {
  background: #008cd2;
  border: #008cd2 1px solid;
}
.delivery-option1-selected .order-now-delivery svg {
  color: #ffffff !important;
}
.pickup-location {
  background: #ffffff;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  border: #008cd2 1px solid;
}
.pickup-subtext {
  font-weight: 600;
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .km-number {
    font-size: 18px;
  }
  .km-box {
    margin-top: 15px;
  }
}

.order-card-left {
  background: #fff;
  text-align: left;
  min-height: 370px;
  color: #000;
  border: 1px solid #008cd2;
  border-radius: 4px;
}
.order-card-left-text-1 {
  color: #000;
  font-weight: 700;
  font-size: 20px;
}
.order-card-left-text {
  color: #000;
}
.order-card-left-heading {
  text-align: left;
  color: #000;
  font-weight: 700;
  font-size: 24px;
}
.order-section2-left-heading {
  text-align: left;
  color: #fff;
}
.left-box-wrap {
  padding-top: 189px;
}
@media only screen and (min-width: 1440px) and (max-width: 1919px) {
  .left-box-wrap {
    padding-top: 211px;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1439px) {
  .left-box-wrap {
    padding-top: 211px;
  }
}
.h1-home-order {
  font-size: 54px;
  font-style: normal;
  font-weight: 600;
  line-height: 77px;
  letter-spacing: 0em;
  text-align: left;
  margin: 5px;
  padding-bottom: 30px;
}
.btn-primary-account_order {
  background: #008cd2;
  color: #fff;
  margin-right: 10px;
}
.benifits-list-item {
  padding-bottom: 20px;
}
.section-3_order {
  background: #56b146;
  margin-top: 3%;

  padding: 0 50px;
}
.section-3_order_inner {
  background: #fff;
  text-align: left;
  min-height: 180px;
  color: #000;
  border: 1px solid #008cd2;
  padding: 30px;
}
.section-3_order_wrap {
  padding: 60px 0;
}
.account-order-card-right {
  border: 1px solid #008cd2;
  text-align: left;
  min-height: 370px;
  border-radius: 4px;
}
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .h1-home-order {
    text-align: center;
  }
  .order-card-left-heading {
    text-align: center;
  }
  .order-card-left-heading {
    text-align: center;
  }
  .order-card-left {
    text-align: center;
  }
  .account-order-card-right {
    text-align: center;
  }
}
@media only screen and (min-width: 375px) and (max-width: 413px) {
  .h1-home-order {
    text-align: center;
  }
  .order-card-left-heading {
    text-align: center;
  }
  .order-card-left-heading {
    text-align: center;
  }
  .order-card-left {
    text-align: center;
  }
  .account-order-card-right {
    text-align: center;
  }
}
@media only screen and (min-width: 414px) and (max-width: 767px) {
  .h1-home-order {
    text-align: center;
  }
  .order-card-left-heading {
    text-align: center;
  }
  .order-card-left-heading {
    text-align: center;
  }
  .order-card-left {
    text-align: center;
  }
  .account-order-card-right {
    text-align: center;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .h1-home-order {
    text-align: center;
  }
  .order-card-left-heading {
    text-align: center;
  }
  .order-card-left-heading {
    text-align: center;
  }
  .order-card-left {
    text-align: center;
  }
  .account-order-card-right {
    text-align: center;
  }
  .left-box-wrap {
    padding-top: 210px;
  }
}

.lang-selected {
  color: #008ace !important;
  font-weight: bold !important;
  font-size: 15px;
}

