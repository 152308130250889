.h1-newcustomer {
  color: #929292;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 77px;
  text-align: left;
  margin: 5px;
}
.newcustomer-heading {
  color: #000000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 77px;
  text-align: left;
  margin: 2px;
  padding-left: 30px;
}
.newcustomer-heading-become {
  color: #000000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  margin: 2px;
  padding-left: 30px;
  padding-right: 10px;
  padding-bottom: 26px;
  padding-top: 37px;
}
.sysco-rep {
  padding: 0 70px;
}
.credit-term {
  padding: 0;
}
.model-header {
  box-shadow: 0 8px 6px -6px #e6e6e659;
  background: white;
}
.model-body {
  background: #f5f5f5;
}
.p-newcustomer {
  font-size: 16px;
  font-weight: 600;
}
.Language-selection-newcustomer {
  padding: 30px 80px;
  display: block;
}
.Language-selection-newcustomer-withoutstep {
  padding: 30px 30px;
  display: block;
}
.col-rightside-newcustomer {
  padding: 20px !important;
}
.col-rightside-newcustomer-box {
  background-color: #fff;
  padding: 20px;
}
.newcustomer-rightside-text {
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
}
.col-left-newcustomer {
  padding: 20px 20px 30px 30px;
}
.Newcustomer-option1 {
  padding: 10px;
  background: white;
  margin-bottom: 10px;
  border-radius: 5px;
}
.Newcustomer-option1:hover {
  background: #f3f3f3;
  cursor: pointer;
  border: #cccccc 1px solid;
}
.Newcustomer-option-businesspartner {
  padding-top: 10px;
}
.Newcustomer-option1-selected {
  border: 2px solid #008cd2;
}
.Newcustomer-option1-selected .order-now-delivery {
  background: #008cd2;
  border: #008cd2 1px solid;
  color: #fff;
}
.Newcustomer-option1-selected .order-now-delivery-selected svg {
  color: #ffffff !important;
  background: #56b146;
  margin-right: 10px;
  border-radius: 50px;
  padding: 5px;
  width: 20px;
  height: 20px;
  position: relative;
  top: 2px;
}
.Newcustomer-option1 .order-now-delivery svg {
  color: #525050 !important;
  margin-right: 10px;
  border-radius: 50px;
  padding: 5px;
  border: 1px solid;
  width: 20px;
  height: 20px;
  position: relative;
  top: 2px;
}
.h1-letsgetstarted-wrap {
  padding: 0px 20px 0px 30px;
}
.h1-letsgetstarted {
  color: #929292;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  padding-top: 20px;
  text-align: left;
  margin: 0px;
}
.option1-title {
  font-weight: 600;
  color: black;
  margin-bottom: 0;
  display: inline-block;
}
.option2-title {
  font-weight: 600;
  color: black;
  margin-bottom: 0;
  display: inline-block;
}
.option3-title {
  font-weight: 600;
  color: black;
  margin-bottom: 0;
}
.business-partner-text {
  padding-bottom: 15px;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}
.qesstion-call {
  font-weight: 700;
  display: inline-block;
  margin: 0;
  padding-top: 10px;
  padding-right: 5px;
}
.newcustomer-btn-continue {
  margin-top: 8vh;
}
