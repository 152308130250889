.rewards-item {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  margin-right: 20px;
  border: 1px solid #eaeaea;
  height: 180px;
  margin-bottom: 10px;
}

.menu-divider-rewards {
  border: 1px #eaeaea solid;
}
.industry-text .ant-form-item {
  margin: 0;
}
.hear-aboutus {
  padding-bottom: 10px;
}
.hear-aboutus .anticon svg {
  color: #008cd2;
}
.rewards-and-receive {
  display: inline-block;
  font-size: 16px;
  font-weight: 800;
  padding-left: 10px;
}
.btn-back .anticon svg {
  color: #008cd2;
}
.linkSeparator {
  display: inline-block;
  margin-bottom: -0.7em;
  height: 2em;
  width: 0.14em;
  background-color: #008cd2;
  font-weight: 100;
  margin-right: 1.2em;
}
.btn-back:focus {
  color: #000 !important;
}
.btn-back:hover {
  color: #000;
}
.btn-back:hover .anticon svg {
  color: #000;
}
.catelog-btn:hover {
  color: #fff;
  background-color: #006a9e !important;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #56b146;
  background: #56b146;
}
.ant-steps-item-finish .ant-steps-item-icon .anticon svg {
  color: #ffffff;
}
@media only screen and (min-width: 768px) and (max-width: 833px) {
  .rewards-item {
    margin-right: 13px;
  }
}
@media only screen and (min-width: 834px) and (max-width: 1024px) {
  .rewards-item {
    margin-right: 1px;
  }
}
