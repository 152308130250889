.col-leftside-aboutyou {
  background: #f5f5f5;
  border-radius: 20px 0 0 20px;
  padding: 70px 20px;
}
.form-btn-link {
  padding: 15px 40px !important;
  height: auto !important;
  background: transparent !important;
  margin-top: 10px;
  color: #008cd2;
  border: none !important;
  box-shadow: none;
}
.col-leftside-aboutyou-model {
  background: #f5f5f5;
  border-radius: 20px 0 0 20px;
  padding: 20px 20px;
}
.ant-steps-item-content {
  display: block;
}
.ncob-steps {
  padding: 16px 0;
  display: block;
}
.ant-steps-small .ant-steps-item-title {
  line-height: 25px;
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: #1890ff !important;
  background: transparent;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #008cd2 !important;
}
.input-first {
  width: 95%;
}

.SyscoAssociate {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  margin-right: 10px;
}
.SyscoAssociate:hover {
  background: #e8e8e8;
  border: 1px solid #008cd2;
}
.SyscoAssociate:hover {
  border: 1px solid #4dc3ff;
}
.SyscoAssociate-selected {
  border: 2px solid #008cd2;
}
.SyscoAssociate .order-now-delivery svg {
  color: #525050 !important;
  margin-right: 10px;
  border-radius: 50px;
  padding: 5px;
  border: 1px solid;
  width: 20px;
  height: 20px;
  position: relative;
  top: 2px;
}
.SyscoAssociate-selected .order-now-delivery svg {
  color: #ffffff !important;
  background: #56b146;
  margin-right: 10px;
  border-radius: 50px;
  padding: 2px;
  width: 20px;
  height: 20px;
}
.ant-form-item {
  margin-bottom: 10px;
}
.ant-form-item-control {
  margin-right: 10px;
}
.about-phone-number input {
  width: 100% !important;
}
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .Language-selection-newcustomer {
    padding: 30px 80px;
  }
  .model-header-rep .ant-steps-horizontal.ant-steps-label-horizontal {
    padding-left: 20px;
    min-height: 100px;
  }
  .language-selection-rep {
    padding: 4px 10px 45px 10px;
    min-height: 132px;
    display: block;
  }
}
@media only screen and (min-width: 375px) and (max-width: 413px) {
  .Language-selection-newcustomer {
    padding: 30px 80px;
  }
  .model-header-rep .ant-steps-horizontal.ant-steps-label-horizontal {
    padding-left: 20px;
    min-height: 100px;
  }
  .language-selection-rep {
    padding: 4px 10px 45px 10px;
    min-height: 132px;
    display: block;
  }
}
@media only screen and (min-width: 414px) and (max-width: 767px) {
  .Language-selection-newcustomer {
    padding: 30px 80px;
  }
  .model-header-rep .ant-steps-horizontal.ant-steps-label-horizontal {
    padding-left: 20px;
    min-height: 100px;
  }
  .language-selection-rep {
    padding: 4px 10px 45px 10px;
    min-height: 132px;
    display: block;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .Language-selection-newcustomer {
    padding: 30px 80px;
  }
  .newcustomer-heading-become {
    font-size: 15px !important;
    line-height: 14px !important;
    padding-top: 23px !important;
  }
  .language-selection-rep {
    padding: 30px 15px;
    display: block;
  }
}
@media only screen and (min-width: 801px) and (max-width: 1024px) {
  .Language-selection-newcustomer {
    padding: 30px 40px !important;
  }
  .newcustomer-heading-become {
    font-size: 14px !important;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1365px) {
  .language-selection-rep {
    padding: 30px 10px 30px 10px;
    min-height: 40px;
    display: block;
  }
  .modelheader-syscorep .newcustomer-heading-become {
    font-size: 15px !important;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1439px) {
  .language-selection-rep {
    padding: 30px 10px 30px 10px;
    min-height: 40px;
    display: block;
  }
  .modelheader-syscorep .newcustomer-heading-become {
    font-size: 15px !important;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1536px) {
  .language-selection-rep {
    padding: 30px 10px 30px 10px;
    min-height: 40px;
    display: block;
  }
  .newcustomer-heading-become-desktop {
    font-size: 18px !important;
  }
  .modelheader-syscorep .newcustomer-heading-become {
    font-size: 15px !important;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1537px) and (max-width: 1920px) {
  .language-selection-rep {
    padding: 30px 10px 30px 10px;
    min-height: 40px;
    display: block;
  }
  .newcustomer-heading-become-desktop {
    font-size: 18px !important;
  }
  .modelheader-syscorep .newcustomer-heading-become {
    font-size: 15px !important;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1921px) {
  .language-selection-rep {
    padding: 30px 10px 30px 10px;
    min-height: 40px;
    display: block;
  }
}
