.order-card-left {
  background: #fff;
  text-align: left;
  min-height: 370px;
  color: #000;
  border: 1px solid #008cd2;
  border-radius: 4px;
}
.order-card-left-text-1 {
  color: #000;
  font-weight: 700;
  font-size: 20px;
}
.order-card-left-text {
  color: #000;
}
.order-card-left-heading {
  text-align: left;
  color: #000;
  font-weight: 700;
  font-size: 24px;
}
.order-section2-left-heading {
  text-align: left;
  color: #fff;
}
.left-box-wrap {
  padding-top: 189px;
}
@media only screen and (min-width: 1440px) and (max-width: 1919px) {
  .left-box-wrap {
    padding-top: 211px;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1439px) {
  .left-box-wrap {
    padding-top: 211px;
  }
}
.h1-home-order {
  font-size: 54px;
  font-style: normal;
  font-weight: 600;
  line-height: 77px;
  letter-spacing: 0em;
  text-align: left;
  margin: 5px;
  padding-bottom: 30px;
}
.btn-primary-account_order {
  background: #008cd2;
  color: #fff;
  margin-right: 10px;
}
.benifits-list-item {
  padding-bottom: 20px;
}
.section-3_order {
  background: #56b146;
  margin-top: 3%;

  padding: 0 50px;
}
.section-3_order_inner {
  background: #fff;
  text-align: left;
  min-height: 180px;
  color: #000;
  border: 1px solid #008cd2;
  padding: 30px;
}
.section-3_order_wrap {
  padding: 60px 0;
}
.account-order-card-right {
  border: 1px solid #008cd2;
  text-align: left;
  min-height: 370px;
  border-radius: 4px;
}
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .h1-home-order {
    text-align: center;
  }
  .order-card-left-heading {
    text-align: center;
  }
  .order-card-left-heading {
    text-align: center;
  }
  .order-card-left {
    text-align: center;
  }
  .account-order-card-right {
    text-align: center;
  }
}
@media only screen and (min-width: 375px) and (max-width: 413px) {
  .h1-home-order {
    text-align: center;
  }
  .order-card-left-heading {
    text-align: center;
  }
  .order-card-left-heading {
    text-align: center;
  }
  .order-card-left {
    text-align: center;
  }
  .account-order-card-right {
    text-align: center;
  }
}
@media only screen and (min-width: 414px) and (max-width: 767px) {
  .h1-home-order {
    text-align: center;
  }
  .order-card-left-heading {
    text-align: center;
  }
  .order-card-left-heading {
    text-align: center;
  }
  .order-card-left {
    text-align: center;
  }
  .account-order-card-right {
    text-align: center;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .h1-home-order {
    text-align: center;
  }
  .order-card-left-heading {
    text-align: center;
  }
  .order-card-left-heading {
    text-align: center;
  }
  .order-card-left {
    text-align: center;
  }
  .account-order-card-right {
    text-align: center;
  }
  .left-box-wrap {
    padding-top: 210px;
  }
}
