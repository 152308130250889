.help-header {
  text-align: center;
}
.help-header-french {
  padding-bottom: 20px;
}
.btn-timeout-wrap {
  text-align: center;
}
.btn-timeout {
  margin: 16px 0;
}
.btn-timeout-model {
  width: 70%;
}
.call-icon-wrap {
  padding: 10px 0;
}
.call-image {
  padding-bottom: 10px;
}
