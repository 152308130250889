.p-industry {
  padding: 30px 30px 0px 30px;
}
.industry-item {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
}
.industry-item:hover {
  background: #e8e8e8;
  border: 1px solid #008cd2;
  cursor: pointer;
}
.col-leftside-yourbusiness {
  padding: 0px 30px 0px 30px;
}
.segment-text {
  padding: 10px 30px 0px 30px;
}
.segment-text .anticon svg {
  color: #008cd2;
}
.segment-form .ant-form-item-control {
  margin: 0;
}
.form-select {
  width: 100%;
}
.yourbusiness-footer {
  padding-bottom: 20px;
}
.industry-icon-wrap {
  border: #bfbfbf 1px solid;
  padding: 2px 5px 4px 6px;
  border-radius: 50px;
  margin-right: 10px;
}
.industry-selected {
  border: #008cd2 2px solid;
}

.industry-item:hover {
  border: #008cd2 1px solid;
}
.industry-selected span {
  background: #008cd2;
  border: #008cd2 1px solid;
}
.segment-lable {
  margin: 0;
}
.ant-select-selection--single .ant-select-selection__rendered {
  margin-top: 6px;
}

.ant-steps-item-process-business {
  border-color: #bfbfbf !important;
  background: transparent;
  color: #bfbfbf !important;
}

.ant-steps-item-process-business .ant-steps-item-icon {
  border-color: #bfbfbf !important;
}

.ant-steps-item-process-business > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}

.ant-steps-item-process-business .ant-steps-item-icon > .ant-steps-icon {
  color: #bfbfbf !important;
  border-color: #bfbfbf !important;
}

.newcustomer-heading-become {
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  margin: 2px;
  padding-left: 30px;
  padding-right: 10px;
  padding-bottom: 26px;
  padding-top: 23px;
  width: 90%;
}

.sysco-rep-business {
  padding: 0 50px;
}

.credit-term {
  padding: 0;
}

@media only screen and (min-width: 360px) and (max-width: 374px) {
  .Language-selection-newcustomer-billing {
    min-height: 320px;
  }
  .Language-selection-newcustomer-without-billing {
    min-height: 272px;
  }
  .Language-selection-newcustomer {
    padding: 5px 10px 245px 10px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal {
    padding-left: 20px;
    min-height: 240px;
  }
  .newcustomer-heading-become {
    width: 90%;
  }
  .sysco-rep-steps .ant-steps-horizontal.ant-steps-label-horizontal {
    padding-left: 20px;
    min-height: 100px;
  }
  .syscorep-language {
    padding: 5px 10px 105px 10px;
    min-height: 130px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 413px) {
  .Language-selection-newcustomer-billing {
    min-height: 320px;
  }
  .Language-selection-newcustomer-without-billing {
    min-height: 272px;
  }
  .Language-selection-newcustomer {
    padding: 5px 10px 245px 10px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal {
    padding-left: 20px;
    min-height: 240px;
  }
  .newcustomer-heading-become {
    width: 90%;
  }
  .sysco-rep-steps .ant-steps-horizontal.ant-steps-label-horizontal {
    padding-left: 20px;
    min-height: 100px;
  }
  .syscorep-language {
    padding: 5px 10px 105px 10px;
    min-height: 130px;
  }
}
@media only screen and (min-width: 414px) and (max-width: 767px) {
  .Language-selection-newcustomer-billing {
    min-height: 320px;
  }
  .Language-selection-newcustomer-without-billing {
    min-height: 272px;
  }
  .Language-selection-newcustomer {
    padding: 5px 10px 245px 10px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal {
    padding-left: 20px;
    min-height: 240px;
  }
  .newcustomer-heading-become {
    width: 90%;
  }
  .sysco-rep-steps .ant-steps-horizontal.ant-steps-label-horizontal {
    padding-left: 20px;
    min-height: 100px;
  }
  .syscorep-language {
    padding: 5px 10px 105px 10px;
    min-height: 130px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .Language-selection-newcustomer {
    padding: 53px 10px;
  }
  .newcustomer-heading-become {
    width: 90%;
    padding-top: 32px !important;
    padding-bottom: 32px;
    max-height: 77px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .Language-selection-newcustomer {
    padding: 30px 10px;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1365px) {
  .Language-selection-newcustomer {
    padding: 30px 60px;
  }
  .newcustomer-heading-become-desktop-wrap p {
    font-size: 16px !important;
    line-height: 14px;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1439px) {
  .Language-selection-newcustomer {
    padding: 30px 60px;
  }
  .newcustomer-heading-become-desktop-wrap p {
    font-size: 16px !important;
    line-height: 14px;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1536px) {
  .Language-selection-newcustomer {
    padding: 30px 60px;
  }
  .newcustomer-heading-become-desktop-wrap .newcustomer-heading-business-desktop {
    font-size: 14px !important;
  }
  .newcustomer-heading-become-desktop-card .newcustomer-heading-business-desktop {
    font-size: 18px !important;
  }
  .newcustomer-heading-become-desktop-wrap p {
    font-size: 16px !important;
    line-height: 14px;
  }
}
@media only screen and (min-width: 1537px) and (max-width: 1920px) {
  .Language-selection-newcustomer {
    padding: 30px 60px;
  }
  .newcustomer-heading-become-desktop-card .newcustomer-heading-business-desktop {
    font-size: 18px !important;
  }
  .newcustomer-heading-become-desktop-wrap p {
    font-size: 16px !important;
    line-height: 14px;
  }
}
