.ant-modal-body {
  padding: 0 !important;
}
.col-leftside {
  background: #008cd2;
  border-radius: 5px;
}
.col-leftside {
  background: #008cd2;
  border-radius: 5px;
}
.col-leftside-catalogue {
  background: #008cd2;
  border-radius: 20px 0 0 20px;
  padding: 50px 0;
}
.ant-modal-content {
  border-radius: 20px !important;
}
.leftside-image {
  width: 370px;
  padding: 40px;
}
.col-rightside-catalogues {
  padding: 29px !important;
}
.catelogue-field-phone input {
  width: 95% !important;
}
.catelogue-field-wrap input {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-modal-close-x {
  background: #008cd2;
  border-radius: 50px;
  margin: 10px;
}
.anticon svg {
  color: white;
}
.catelog-lable {
  padding-bottom: 10px;
  display: block;
  padding-top: 10px;
}
.catelog-btn {
  padding: 15px 40px !important;
  height: auto !important;
  background: #008cd2 !important;
  margin-top: 10px;
}
.dowload-text {
  margin: 0;
  font-weight: 700;
  font-size: 20px;
}
.Thank-you {
  position: absolute;
  left: 40px;
  top: 220px;
  font-weight: 600;
}
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .col-leftside-catalogue {
    background: transparent;
    border-radius: 20px;
    padding: 30px 0px 0px 0px;
    text-align: center;
  }
  .leftside-image {
    width: 340px;
    padding: 36px 40px 0px 40px;
  }
  .Thank-you {
    position: relative;
    left: 0;
    top: 0;
    font-weight: 600;
    text-align: center;
    padding-top: 20px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 413px) {
  .col-leftside-catalogue {
    background: transparent;
    border-radius: 20px;
    padding: 30px 0px 0px 0px;
    text-align: center;
  }
  .leftside-image {
    width: 340px;
    padding: 36px 40px 0px 40px;
  }
  .Thank-you {
    position: relative;
    left: 0;
    top: 0;
    font-weight: 600;
    text-align: center;
    padding-top: 20px;
  }
}
@media only screen and (min-width: 414px) and (max-width: 767px) {
  .col-leftside-catalogue {
    background: transparent;
    border-radius: 20px;
    padding: 30px 0px 0px 0px;
    text-align: center;
  }
  .leftside-image {
    width: 340px;
    padding: 36px 40px 0px 40px;
  }
  .Thank-you {
    position: relative;
    left: 0;
    top: 0;
    font-weight: 600;
    text-align: center;
    padding-top: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .col-leftside-catalogue {
    background: transparent;
    border-radius: 20px;
    padding: 30px 0px 0px 0px;
    text-align: center;
  }
  .leftside-image {
    width: 340px;
    padding: 36px 40px 0px 40px;
  }
  .Thank-you {
    position: relative;
    left: 0;
    top: 0;
    font-weight: 600;
    text-align: center;
    padding-top: 20px;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1536px) {
  .catelog-card .ant-card-body {
    min-height: 280px;
  }
}
@media only screen and (min-width: 1537px) and (max-width: 1920px) {
  .catelog-card .ant-card-body {
    min-height: 250px;
  }
}
