@import '~antd/dist/antd.css';
.catelog-view {
  color: #008cd2 !important;
}
/* Landing page style */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-layout {
  background-color: white;
}
.header {
  padding: 0;
  background-color: white;
}
.section-1 {
  box-shadow: 0px 7px 7px rgb(0 0 0 / 2%);
}
.section-2 {
  padding: 0 50px;
  padding-top: 3%;
}
.section-2-ordernow {
  padding-bottom: 50px;
}
.section-3 {
  margin-top: 3%;
  margin-bottom: 3%;
  padding: 0 50px;
}
.section-4 {
  padding: 50px 50px;
  background: #f5f5f5;
}
.section-5 {
  padding: 50px 50px;
  margin: 0 !important;
}
.section-6 {
  padding: 50px 50px;
  background: #2b2f33;
  margin: 0 !important;
}
.account-card-left {
  background: #008cd2;
  text-align: left;
  min-height: 180px;
  color: #fff;
}
.account-card-left-text {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}
.account-card-left-subtext {
  color: #fff;
  font-weight: 100;
  padding-bottom: 24px;
}
.account-card-icon {
  padding-bottom: 10px;
}
.account-card-right {
  border: 1px solid #008cd2;
  text-align: left;
  min-height: 196px;
}
.h1-home {
  font-size: 54px;
  font-style: normal;
  font-weight: 600;
  line-height: 77px;
  letter-spacing: 0em;
  text-align: left;
  margin: 5px;
}
.p-home {
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}
.section-2-wrap {
  padding-top: 5%;
}
.section-3-box-1 {
  text-align: left;
}
.section-4-heading {
  width: 40%;
  text-align: left;
  color: #000;
  font-weight: 600;
  font-size: 24px;
}
.section-5-heading {
  width: 60%;
  text-align: left;
  padding-bottom: 2%;
  font-weight: 700;
}
.section-4-subheading {
  width: 60%;
  text-align: left;
  padding-bottom: 2%;
  padding-top: 2%;
  font-weight: 700;
}
.section-5-inner-1 {
  background: #008cd2;
  color: #fff;
  min-height: 310px;
  padding: 50px 30px;
  text-align: center;
}
.section-5-inner-1 h1 {
  color: #fff;
}
.section-5-inner-3 h1 {
  color: #fff;
  margin: 0;
}
.section-5-inner-2 {
  border: 1px solid #008cd2;
  min-height: 310px;
  padding: 50px 30px;
  text-align: center;
}
.section-5-inner-3 {
  background: #008cd2;
  color: #fff;
  min-height: 310px;
  padding: 50px 30px;
  border-radius: 5px;
  text-align: center;
}
.section-5-inner-1-heading {
  margin: 0;
}
.section-5-inner-2-heading-one {
  font-weight: 600;
  color: #000;
  margin: 0;
}
.section-5-inner-2-heading-two {
  font-weight: 600;
  color: #000;
  margin: 0;
  padding-bottom: 12px;
}
.footer-box1-subtext {
  padding-bottom: 10px;
}
.section-5-inner-3-a {
  color: #fff;
  text-decoration: underline;
}
.section-5-inner-3-a:hover {
  color: #000;
  text-decoration: underline;
}
.footer-question-item {
  padding-top: 10px;
  font-size: 16px;
}
.footer-question-item-wrap {
  display: flex;
  justify-content: center;
}
.footer-question-sub {
  padding-bottom: 10px;
}
.section-6-box-1 {
  text-align: left;
  color: #fff;
}
.section-6-box2-heading {
  color: #fff;
  text-transform: uppercase;
}
.footer-category-support {
  padding-top: 10px;
}
.section-6-box2-list {
  list-style: none;

  text-align: left;
}
.section-6-box2-list li {
  padding-bottom: 5px;
}
.section-6-box2-list li a {
  color: #a1a1a1;
  padding-bottom: 10px;
}
.subfooter {
  text-align: left;
  color: white;
}
.ant-menu-horizontal {
  border-bottom: 1px solid #fff;
  margin-left: 25px;
}
.header-sec-3 {
  padding: 14px;
}
.header-sec-2 {
  padding: 8px;
}
.sysco-header {
  padding: 0 48px;
}
.header-right {
  float: right;
}
.Language-selection {
  padding-right: 10px;
}
.btn-business-link {
  color: #56b146;
  font-weight: 600;
}
.ant-menu-submenu-title {
  font-weight: 600;
}
.nav-item {
  color: #008cd2;
}
.btn-primary-account {
  background-color: white;
  color: #000;
  height: 48px;
  font-weight: 600;
}
.btn-primary-business-account {
  background: #56b146;
  border: none;
  height: 48px;
}
.ant-btn:active {
  color: #fff !important;
}
.ant-btn:focus {
  color: #fff !important;
}
.btn-business-link:active {
  color: #56b146 !important;
}
.btn-business-link:focus {
  color: #56b146 !important;
}
.catelog-card {
  margin: 0 20px;
}
.catelog-card .ant-card-body {
  padding: 0 0 30px 0;
  text-align: left;
}
.catelog-view {
  text-decoration: underline;
  font-weight: 600;
  padding-left: 20px;
}
.epbijV {
  background-color: rgb(0 140 210 / 56%) !important;
  box-shadow: 0 0 1px 3px rgb(0 140 210) !important;
}
.VHmVZ {
  width: 100%;
  justify-content: flex-end;
  text-align: right;
}
.btPwzg {
  background-color: rgb(239 250 255) !important;
}
.btPwzg:disabled {
  position: absolute;
  bottom: -36px;
  left: 30px;
}
.jsaYsO {
  position: absolute;
  bottom: -34px;
  left: 90px;
}
.jsaYsO:hover:enabled,
.jsaYsO:focus:enabled {
  background-color: rgb(0 140 210) !important;
}
.jsaYsO {
  background-color: rgb(0 140 210) !important;
  color: #fff !important;
}
.catelog-img {
  width: 160px;
  height: 213px;
}
.catelogu-title {
  padding-top: 16px;
  padding-left: 20px;
  word-wrap: break-word;
  width: 150px;
}
.divider {
  border: 1px solid #e8e8e8;
}

.home-customer {
  padding-bottom: 21px;
  width: 230px;
  font-weight: 600;
  font-size: 18px;
  color: #000;
}
.homepage-section-3-item-image1 {
  padding-bottom: 10px;
}
.styling-example {
  min-height: 80px;
}
.catelog-view .anticon svg {
  color: #008cd2 !important;
}
.menu-link {
  font-weight: 600;
  color: #008cd2 !important;
}
.menu-divider {
  border: 1px #f7f7f7 solid;
}
.social-media {
  width: 40px;
  padding-bottom: 10px;
}
.social-media-list {
  list-style: none;
}
.search-wrap .anticon svg {
  color: #868686;
  margin-right: 10px;
  font-size: 20px;
}
input:focus:invalid,
select:focus:invalid,
textarea:focus:invalid {
  border-color: red;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #56b146;
  background: #56b146;
}

.react-tel-input .form-control.invalid-number {
  border: 1px solid #cacaca !important;
  background-color: #ffffff !important;
  border-left-color: #ffffff !important;
}
.react-tel-input .form-control.invalid-number:focus {
  border: 1px solid red !important;
  background-color: #ffffff !important;
  border-left-color: #ffffff !important;
}
.homepage-infobox-text {
  font-weight: 600;
  color: #000;
}
.homepage-infobox-subtext {
  font-size: 18px;
  color: #5d5d5d;
  width: 80%;
}

/* Loader */
.loader-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #0000002e;
  top: 0;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
.loader {
  width: 100px;
  height: 100px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='margin: auto;display: block;' width='100px' height='100px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'%3E%3Ccircle cx='50' cy='50' r='40' stroke='%2356b146' stroke-width='5' fill='none'%3E%3C/circle%3E%3Cpath d='M15 50 Q32.5 40 50 50 Q67.5 60 85 50 A35 35 0 0 1 15 50' fill='%23008cd2'%3E%3Canimate attributeName='d' repeatCount='indefinite' dur='1s' calcMode='spline' keyTimes='0;0.5;1' values='M15 50 Q32.5 40 50 50 Q67.5 60 85 50 A35 35 0 0 1 15 50;M15 50 Q32.5 60 50 50 Q67.5 40 85 50 A35 35 0 0 1 15 50;M15 50 Q32.5 40 50 50 Q67.5 60 85 50 A35 35 0 0 1 15 50' keySplines='0.5 0 0.5 1;0.5 0 0.5 1'%3E%3C/animate%3E%3C/path%3E%3C/svg%3E");
}

@media only screen and (min-width: 360px) and (max-width: 374px) {
  .section-6-box-1 {
    text-align: center;
  }
  .h1-home {
    text-align: center;
  }
  .p-home {
    text-align: center;
  }
  .account-card-left {
    text-align: center;
  }
  .account-card-right {
    text-align: center;
    margin-top: 10px;
  }
  .section-3-box-1 {
    text-align: center;
  }
  .homepage-infobox-subtext {
    width: 100%;
  }
  .section-4-heading {
    width: 100%;
    text-align: center;
  }
  .section-5-heading {
    width: 100%;
    text-align: center;
    font-size: 24px;
  }
  .section-5-inner-1 {
    padding: 50px 10px;
  }
  .section-5-inner-2 {
    padding: 50px 10px;
  }
  .section-5-inner-3 {
    padding: 50px 10px;
  }
  .section-5 {
    padding: 50px 0 0 0;
  }
  .section-2 {
    padding: 0 10px;
  }
  .section-2-ordernow {
    padding-bottom: 10px;
  }
  .home-customer {
    width: 100%;
  }
  .section-4-subheading {
    width: 100%;
    text-align: center;
  }
  .section-6-box2-list {
    padding-left: 0;
    text-align: center;
  }
  .social-media-list {
    text-align: center;
  }
  .section-5-inner-2 {
    border: none;
  }
  .wrap-section-5-inner-1 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .wrap-section-5-inner-2 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .wrap-section-5-inner-3 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .ant-modal-close-x {
    line-height: 42px;
    width: 40px;
    height: 40px;
    margin: 20px 10px !important;
  }
  .Language-selection-newcustomer-withoutstep {
    padding: 30px 10px !important;
  }
  .confimation-box {
    padding: 50px 10px;
  }
  .home-header-logo {
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    display: flex;
  }
  .header-sec-3-mobile {
    text-align: center;
  }
  .header-sec-2 {
    padding: 4px;
  }
  .social-footer-mobile {
    display: flex;
    justify-content: space-evenly;
  }
}
@media only screen and (min-width: 375px) and (max-width: 413px) {
  .h1-home {
    text-align: center;
  }
  .p-home {
    text-align: center;
  }
  .account-card-left {
    text-align: center;
  }
  .account-card-right {
    text-align: center;
    margin-top: 10px;
  }
  .section-3-box-1 {
    text-align: center;
  }
  .homepage-infobox-subtext {
    width: 100%;
  }
  .section-4-heading {
    width: 100%;
    text-align: center;
  }
  .section-5-heading {
    width: 100%;
    text-align: center;
    font-size: 24px;
  }
  .section-5-inner-1 {
    padding: 50px 10px;
  }
  .section-5-inner-2 {
    padding: 50px 10px;
  }
  .section-5-inner-3 {
    padding: 50px 10px;
  }
  .section-5 {
    padding: 50px 0 0 0;
  }
  .section-2 {
    padding: 0 10px;
  }
  .section-2-ordernow {
    padding-bottom: 10px;
  }
  .home-customer {
    width: 100%;
  }
  .section-4-subheading {
    width: 100%;
    text-align: center;
  }
  .section-6-box2-list {
    padding-left: 0;
    text-align: center;
  }
  .section-6-box-1 {
    text-align: center;
  }
  .social-media-list {
    text-align: center;
  }
  .section-5-inner-2 {
    border: none;
  }
  .wrap-section-5-inner-1 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .wrap-section-5-inner-2 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .wrap-section-5-inner-3 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .ant-modal-close-x {
    line-height: 42px;
    width: 40px;
    height: 40px;
    margin: 20px 10px !important;
  }
  .Language-selection-newcustomer-withoutstep {
    padding: 30px 10px !important;
  }
  .confimation-box {
    padding: 50px 10px;
  }
  .home-header-logo {
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    display: flex;
  }
  .header-sec-3-mobile {
    text-align: center;
  }
  .header-sec-2 {
    padding: 4px;
  }
  .social-footer-mobile {
    display: flex;
    justify-content: space-evenly;
  }
}
@media only screen and (min-width: 414px) and (max-width: 767px) {
  .h1-home {
    text-align: center;
  }
  .p-home {
    text-align: center;
  }
  .account-card-left {
    text-align: center;
  }
  .account-card-right {
    text-align: center;
    margin-top: 10px;
  }
  .section-3-box-1 {
    text-align: center;
  }
  .homepage-infobox-subtext {
    width: 100%;
  }
  .section-4-heading {
    width: 100%;
    text-align: center;
  }
  .section-5-heading {
    width: 100%;
    text-align: center;
    font-size: 24px;
  }
  .section-5-inner-1 {
    padding: 50px 10px;
  }
  .section-5-inner-2 {
    padding: 50px 10px;
  }
  .section-5-inner-3 {
    padding: 50px 10px;
  }
  .section-5 {
    padding: 50px 0 0 0;
  }
  .section-2 {
    padding: 0 10px;
  }
  .section-2-ordernow {
    padding-bottom: 10px;
  }
  .home-customer {
    width: 100%;
  }
  .section-4-subheading {
    width: 100%;
    text-align: center;
  }
  .section-6-box2-list {
    padding-left: 0;
    text-align: center;
  }
  .social-media-list {
    text-align: center;
  }
  .section-5-inner-2 {
    border: none;
  }
  .wrap-section-5-inner-1 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .wrap-section-5-inner-2 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .wrap-section-5-inner-3 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .section-6-box-1 {
    text-align: center;
  }
  .ant-modal-close-x {
    line-height: 42px;
    width: 40px;
    height: 40px;
    margin: 20px 10px !important;
  }
  .Language-selection-newcustomer-withoutstep {
    padding: 30px 10px !important;
  }
  .confimation-box {
    padding: 50px 10px;
  }
  .home-header-logo {
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    display: flex;
  }
  .header-sec-3-mobile {
    text-align: center;
  }
  .header-sec-2 {
    padding: 4px;
  }
  .social-footer-mobile {
    display: flex;
    justify-content: space-evenly;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .section-5-inner-wrap {
    zoom: 1.2;
  }
  .section-5-inner-1 {
    margin-bottom: 10px;
  }
  .section-5-inner-2 {
    margin-bottom: 10px;
  }
  .section-4-heading {
    width: 100%;
  }
  .ant-modal-close-x {
    line-height: 42px;
    width: 40px;
    height: 40px;
    margin: 20px 10px !important;
  }
  .Language-selection-newcustomer-withoutstep {
    padding: 30px 10px !important;
  }
  .confimation-box {
    padding: 50px 10px;
  }
  .contactus-model {
    width: 700px !important;
  }
  .home-header-logo {
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    display: flex;
  }
  .header-sec-3-mobile {
    text-align: center;
  }
  .header-sec-2 {
    padding: 4px;
  }
}
@media only screen and (min-width: 801px) and (max-width: 1024px) {
  .contactus-model {
    width: 990px !important;
  }
  .section-5-inner-3 {
    padding: 72px 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .contactus-model {
    width: 700px !important;
  }
  .section-5-inner-ipad {
    zoom: 1.2;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1536px) {
  .catelogu-title {
    min-height: 90px;
  }
}
@media only screen and (min-width: 1537px) and (max-width: 1920px) {
  .catelogu-title {
    min-height: 60px;
  }
}
