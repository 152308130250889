.ant-modal-body {
  padding: 0 !important;
}
.col-leftside-contact {
  background: #ffffff;
  border-radius: 20px;
  padding: 70px 40px 10px 40px;
}
.ant-modal-content {
  border-radius: 20px !important;
}
.leftside-image {
  width: 370px;
  padding: 40px;
}
.col-rightside {
  padding: 160px 40px 40px 40px;
}
.ant-modal-close-x {
  background: #008cd2;
  border-radius: 50px;
  margin: 10px;
}
.anticon svg {
  color: white;
}
.catelog-lable {
  padding-bottom: 10px;
  display: block;
  padding-top: 10px;
}
.catelog-btn {
  padding: 15px 40px !important;
  height: auto !important;
  background: #008cd2 !important;
  margin-top: 10px;
  color: #fff;
}
.download-text-contact {
  margin: 0;
  font-weight: 700;
  font-size: 26px;
}
.Thank-you {
  position: absolute;
  left: 40px;
  top: 220px;
  font-weight: 600;
}
.thankyou-wraper {
  padding: 40px;
}
.contactus-form .col-leftside-contact input {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.col-rightside-form input {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
  width: 95%;
}
.contact-phone-field input {
  width: 95% !important;
}
.contact-submit-btn {
  padding: 20px 40px;
}

@media only screen and (min-width: 360px) and (max-width: 374px) {
  .col-leftside-contact {
    padding: 70px 40px 0px 40px;
  }
  .col-rightside {
    padding: 0px 40px 10px 40px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 413px) {
  .col-leftside-contact {
    padding: 70px 40px 0px 40px;
  }
  .col-rightside {
    padding: 0px 40px 10px 40px;
  }
}
@media only screen and (min-width: 414px) and (max-width: 767px) {
  .col-leftside-contact {
    padding: 70px 40px 0px 40px;
  }
  .col-rightside {
    padding: 0px 40px 10px 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .col-leftside-contact {
    padding: 70px 40px 0px 40px;
  }
  .col-rightside {
    padding: 0px 40px 10px 40px;
  }
}
