.Language-selection-newcustomer-withoutstep {
  padding: 30px 30px;
  display: block;
}
.col-centered-newcustomer-address {
  background: #f5f5f5;
  border-radius: 20px 0 0 20px;
  padding: 20px 20px;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

@media only screen and (min-width: 360px) and (max-width: 374px) {
  .newcustomer-heading {
    font-size: 12px;
  }
  .newcustomer-heading-terms {
    font-size: 11px !important;
  }
  .tandc-pdf {
    width: 310px;
  }
  .tandc-pdf .react-pdf__Page__svg,
  .tandc-pdf svg {
    width: 310px !important;
  }
}
@media only screen and (min-width: 375px) and (max-width: 413px) {
  .newcustomer-heading {
    font-size: 12px;
  }
  .newcustomer-heading-terms {
    font-size: 11px !important;
  }
  .tandc-pdf {
    width: 310px;
  }
  .tandc-pdf .react-pdf__Page__svg,
  .tandc-pdf svg {
    width: 310px !important;
  }
  .tandc-pdf .react-pdf__Page__svg {
    width: 310px !important;
  }
}
@media only screen and (min-width: 414px) and (max-width: 767px) {
  .newcustomer-heading {
    font-size: 12px;
  }
  .tandc-pdf {
    width: 360px;
  }
  .tandc-pdf .react-pdf__Page__svg,
  .tandc-pdf svg {
    width: 360px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 600px) {
  .newcustomer-heading {
    font-size: 12px;
  }
  .tandc-pdf {
    width: 660px;
  }
  .tandc-pdf .react-pdf__Page__svg,
  .tandc-pdf svg {
    width: 600px !important;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1365px) {
  .tandc-pdf {
    width: 1140px;
  }
  .tandc-pdf .react-pdf__Page__svg,
  .tandc-pdf svg {
    width: 600px !important;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1439px) {
  .tandc-pdf {
    width: 1150px;
  }
  .tandc-pdf .react-pdf__Page__svg,
  .tandc-pdf svg {
    width: 600px !important;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1536px) {
  .tandc-pdf {
    width: 1150px;
  }
  .tandc-pdf .react-pdf__Page__svg,
  .tandc-pdf svg {
    width: 600px !important;
  }
}
@media only screen and (min-width: 1537px) and (max-width: 1920px) {
  .tandc-pdf {
    width: 1150px;
  }
  .tandc-pdf .react-pdf__Page__svg,
  .tandc-pdf svg {
    width: 600px !important;
  }
}
