.km-box {
  background: #008cd2;
  text-align: center;
  color: white;
  border-radius: 5px;
  margin-right: 16px;
}
.delivery-sysco-address {
  margin: 0;
}
.industry-item-pickup {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
}
.industry-item-pickup:hover {
  background: #e8e8e8;
  cursor: pointer;
}
.km-number {
  font-size: 30px;
  padding-top: 5px;
  margin: -5px;
  font-weight: 700;
}
.km-text {
  font-size: 18px;
  font-weight: 400;
  padding-top: 0;
  padding-bottom: 10px;
  margin: -7px -5px;
}
.delivery-sysco-cat {
  margin: 0;
  font-weight: 700;
  padding-top: 10px;
}
.pickup-footer {
  padding-bottom: 20px;
}
.delivery-option1-selected {
  border: 1px solid #008cd2;
}
.delivery-option1-selected .order-now-delivery {
  background: #008cd2;
  border: #008cd2 1px solid;
}
.delivery-option1-selected .order-now-delivery svg {
  color: #ffffff !important;
}
.pickup-location {
  background: #ffffff;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  border: #008cd2 1px solid;
}
.pickup-subtext {
  font-weight: 600;
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .km-number {
    font-size: 18px;
  }
  .km-box {
    margin-top: 15px;
  }
}
