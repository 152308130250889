.col-leftside-pickup {
  background: #f5f5f5;
  border-radius: 20px 0 0 20px;
  padding: 10px 0px 0px 20px;
}
.estimate-dropdown .anticon svg {
  color: #008cd2;
}
.estimate-dropdown-lable {
  padding-bottom: 10px;
  display: block;
  padding-top: 0;
}
.delivery-footer {
  padding-bottom: 20px;
}
.receive-order-delivery {
  width: 95%;
  cursor: pointer;
}
.ant-select-selection--single {
  height: 42px;
  width: 200px;
}
.industry-item-delivery {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  width: 95%;
  cursor: pointer;
}
.your-order-slot {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
.your-order-slot:hover {
  background: #e8e8e8;
}
.day-selected {
  border: #008cd2 2px solid;
}
.industry-item-delivery:hover {
  background: #e8e8e8;
}
.pickup-option1-selected {
  border: 2px solid #008cd2;
}
.pickup-option1-selected .order-now-delivery svg {
  color: #ffffff !important;
  background: #56b146;
  margin-right: 10px;
  border-radius: 50px;
  padding: 2px;
  width: 20px;
  height: 20px;
}
.order-now-delivery svg {
  color: #525050 !important;
  margin-right: 10px;
  border-radius: 50px;
  padding: 5px;
  border: 1px solid;
  width: 20px;
  height: 20px;
  position: relative;
  top: 2px;
}

.order-model-body {
  min-height: 60vh;
}
.catelog-btn-disabled {
  padding: 15px 40px !important;
  height: auto !important;
  background: #8f8f8f !important;
  margin-top: 10px;
}
@media only screen and (min-width: 360px) and (max-width: 374px) {
  .col-leftside-pickup {
    padding: 10px 15px 10px 20px;
  }
  .km-number {
    font-size: 18px !important;
    padding-top: 5px;
    margin: 4px;
    font-weight: 600;
  }
  .delivery-sysco-cat {
    padding-top: 0px !important;
  }
}
@media only screen and (min-width: 375px) and (max-width: 413px) {
  .col-leftside-pickup {
    padding: 10px 15px 10px 20px;
  }
  .km-number {
    font-size: 18px !important;
    padding-top: 5px;
    margin: 4px;
    font-weight: 600;
  }
  .delivery-sysco-cat {
    padding-top: 0px !important;
  }
}
@media only screen and (min-width: 414px) and (max-width: 767px) {
  .col-leftside-pickup {
    padding: 10px 15px 10px 20px;
  }
  .km-number {
    font-size: 18px !important;
    padding-top: 5px;
    margin: 4px;
    font-weight: 600;
  }
  .delivery-sysco-cat {
    padding-top: 0px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
}
