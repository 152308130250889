.complete-btn {
  padding: 15px 40px !important;
  height: auto !important;
  background: white !important;
  margin-top: 10px;
  border: none;
  box-shadow: none;
  color: #000;
}
.complete-btn:hover {
  color: #000;
  background-color: #ededed !important;
  border-color: #40a9ff;
}
.complete-btn:active {
  color: #000 !important;
}
.complete-btn:focus {
  color: #000 !important;
}

.col-leftside-becomeacustomer-complte {
  background: #008cd2;
  padding: 20px 20px;
}
.register {
  color: #fff;
}
.RightOutlined-complete svg {
  color: #7b7b7b;
}
