.btn-confimation {
  background-color: transparent;
  color: #fff;
  height: 48px;
  border: 1px solid #fff;
  width: 98%;
}
.confimation-box {
  background: #008cd2;
  color: #fff;
  min-height: 310px;
  padding: 50px 30px;
  border-radius: 5px;
}
.btn-confimation-wrap {
  text-align: center;
  margin-bottom: 30px;
}
.confimation-heading {
  color: #fff;
}
