.shippingdetails-form-wrap input {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.biiling-shipping-same {
  padding-top: 16px;
}
.form-select-shipping-address {
  width: 100%;
  height: 32px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background: #fff;
}
.billing-verify-checkbox {
  padding-top: 10px;
}
